<template>
  <div class="d-flex flex-column h-100">
    <quick-message
      class="mb-2"
      with-icon
      :show.sync="hasMessage"
      :message="message"
      :type="typeMessage"
    />
    <div class="d-flex flex-column h-100 ">
      <z-profile-image
        type="user"
        :business-line="businessLine"
        size="lg"
        class="mt-1 mb-3"
      />
      <div class="d-lg-flex justify-content-between">
        <z-input
          v-model="draftName"
          label="Nombre"
          class="card-detail-input mt-4"
          :value="name"
          :state="nameState"
          :disabled="!enableEdit"
        />
        <z-input
          v-model="draftContact"
          label="Contacto"
          class="card-detail-input mt-4"
          :value="contact"
          :state="contactState"
          :disabled="!enableEdit"
        />
      </div>
      <div class="d-lg-flex justify-content-between">
        <z-input
          v-model="draftEmail"
          class="card-detail-input mt-4"
          label="Correo electrónico"
          :state="emailState"
          :value="email"
          :disabled="!enableEdit"
        />
        <z-input
          v-model="draftPhone"
          class="card-detail-input mt-4"
          label="Telefono"
          :state="phoneState"
          :value="phone"
          type="tel"
          :disabled="!enableEdit"
        />
      </div>
      <div v-if="enableEdit" class="mt-4 d-lg-flex">
        <z-button
          :disabled="isLoading || !validForm"
          :loading="isLoading"
          class="z-action mr-2"
          @click="editInformation"
        >
          Guardar cambios
        </z-button>
        <z-button class="z-action" @click="handleCancel">
          Cancelar
        </z-button>
      </div>
      <div v-else class="mt-4">
        <z-button class="z-action" @click="handleEdit">
          Editar
        </z-button>
      </div>
    </div>
  </div>
</template>

<script>
import { validateEmail, validatePhone, validateName } from "@/utils/strings";
import ZProfileImage from "@/app/components/ZProfileImage";
import { formatSendPhone } from "@zubut/common/src/utils/strings";

export default {
  name: "CardDetailInfo",
  components: {
    ZProfileImage
  },
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: null
    },
    contact: {
      type: String,
      default: null
    },
    phone: {
      type: String,
      default: null
    },
    businessLine: {
      type: Number,
      default: null
    },
    email: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      enableEdit: false,
      isLoading: false,
      wasEditedUser: false,
      hasMessage: false,
      typeMessage: "success",
      message: "",
      draftName: "",
      draftContact: "",
      draftEmail: "",
      draftPhone: "",
      vueTel: ""
    };
  },

  computed: {
    emailState() {
      if (!this.enableEdit) {
        return null;
      }
      if (!this.draftEmail) {
        return true;
      }
      return this.draftEmail.length === 0
        ? null
        : validateEmail(this.draftEmail);
    },

    phoneState() {
      if (!this.enableEdit) {
        return null;
      }
      return this.draftPhone.length === 0
        ? null
        : validatePhone(this.draftPhone);
    },

    nameState() {
      if (!this.enableEdit) {
        return null;
      }
      return this.draftName.length === 0 ? null : validateName(this.draftName);
    },

    contactState() {
      if (!this.enableEdit) {
        return null;
      }
      return this.draftContact == null || this.draftContact.length === 0
        ? null
        : validateName(this.draftContact);
    },

    validForm() {
      return (
        this.wasEditedUser &&
        this.emailState &&
        (this.phoneState == null || this.phoneState) &&
        this.nameState &&
        (!this.hasContact || this.contactState)
      );
    }
  },
  watch: {
    draftEmail(newVal) {
      if (newVal && newVal.trim() !== this.email) {
        this.wasEditedUser = true;
      } else {
        this.wasEditedUser = false;
      }
    },

    draftName(newVal) {
      if (newVal && newVal.trim() !== this.name) {
        this.wasEditedUser = true;
      }
    },

    draftContact(newVal) {
      if (newVal && newVal.trim() !== this.contact) {
        this.wasEditedUser = true;
      }
    },

    draftPhone(newVal) {
      if (newVal && newVal.trim() !== this.phone) {
        this.wasEditedUser = true;
      }
    }
  },
  mounted() {
    this.draftName = this.name;
    this.draftContact = this.contact;
    this.draftEmail = this.email;
    this.draftPhone = this.phone;
    this.wasEditedUser = false;
  },
  methods: {
    handleEdit() {
      this.enableEdit = true;
    },

    handleCancel() {
      this.enableEdit = false;
      this.draftName = this.name;
      this.draftContact = this.contact;
      this.draftEmail = this.email;
      this.draftPhone = this.phone;
      this.wasEditedUser = false;
    },
    validPhone({ number, isValid }) {
      if (isValid) this.draftPhone = number.e164;
    },

    editInformation() {
      this.isLoading = true;
      Promise.all([this.editUser()])
        .then(() => {
          this.wasEditedUser = false;
          this.message = "Datos editados con exito";
          this.typeMessage = "success";
          this.hasMessage = true;
          setTimeout(() => {
            this.hasMessage = false;
          }, 5000);
        })
        .catch(err => {
          this.message = err;
          this.typeMessage = "error";
          this.hasMessage = true;
          setTimeout(() => {
            this.hasMessage = false;
          }, 5000);
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
          this.enableEdit = false;
        });
    },

    editUser() {
      return new Promise((resolve, reject) => {
        const id = this.id;
        const data = {
          name: this.draftName,
          phone:
            this.draftPhone.length !== 0
              ? formatSendPhone(this.draftPhone)
              : undefined
        };
        data.contact = this.draftContact ? this.draftContact : undefined;
        data.email = this.draftEmail ? this.draftEmail : undefined;
        this.$store
          .dispatch("user/updateUser", { id, data })
          .then(() => {
            this.$emit("update:name", data.name);
            this.$emit("update:contact", data.contact);
            this.$emit("update:phone", data.phone);
            this.$emit("update:email", this.draftEmail);
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.z-action {
  width: 100%;
  margin-bottom: 10px;
}

@include media-breakpoint-up(lg) {
  .card-detail-input {
    width: 48%;
  }

  .z-action {
    width: auto;
    margin-bottom: 0;
  }
}
</style>
