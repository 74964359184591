<template>
  <div class="d-flex h-100 flex-column">
    <slot name="message"></slot>
    <div class="mb-2">
      <div class="font-weight-bold">Modalidades de entrega</div>
      <div v-if="isUser" class="text-sm enable-service-subtitle mt-3">
        Gestiona las modalidades de entrega que {{ companyName }} te ofrece.
        Para solicitar un tipo de entrega actívala presionando el switch.
      </div>
    </div>
    <div>
      <div
        class="border-bottom pb-3 d-flex justify-content-between align-items-center mt-3"
        v-if="
          visibleServices.findIndex(type => ServiceType.isMultiPoint(type)) > -1
        "
      >
        <div class="d-flex align-items-top">
          <service-type-avatar :type="ServiceType.NUM_SIMPLE" />
          <div class="ml-4 text-sm">
            <div class="font-weight-bold">Entrega Express</div>
            <div v-if="isUser" class="enable-service-description">
              Nuestra promesa de entrega es en 120min, costo calculado por KM
            </div>
          </div>
        </div>
        <div class="d-flex">
          <status-badge
            v-if="expressService"
            class="mr-3"
            statusName="Activa"
            type="green"
          />

          <b-form-checkbox
            v-model="expressService"
            data-test-id="checkbox-express"
            class="enable-services-switch"
            :disabled="loading"
            switch
          />
        </div>
      </div>

      <div
        class="border-bottom pb-3 d-flex justify-content-between align-items-center mt-3"
        v-if="
          visibleServices.findIndex(type => type === ServiceType.NUM_PARCEL) >
            -1
        "
      >
        <div class="d-flex align-items-top">
          <service-type-avatar :type="ServiceType.NUM_PARCEL" />
          <div class="ml-4 text-sm">
            <div class="font-weight-bold">Entrega de Guías</div>
            <div v-if="isUser" class="enable-service-description">
              Recolectamos y entregamos tu paquete el mismo día o al día
              siguiente
            </div>
          </div>
        </div>
        <div class="d-flex">
          <status-badge
            v-if="parcelService"
            class="mr-3"
            statusName="Activa"
            type="green"
          />
          <b-form-checkbox
            v-model="parcelService"
            data-test-id="checkbox-parcels"
            class="enable-services-switch"
            :disabled="loading"
            switch
          />
        </div>
      </div>

      <div
        class="border-bottom pb-3 d-flex justify-content-between align-items-center mt-3"
        v-if="
          visibleServices.findIndex(
            type => type === ServiceType.NUM_RENT_PER_HOUR
          ) > -1
        "
      >
        <div class="d-flex align-items-top">
          <service-type-avatar
            :type="ServiceType.NUM_RENT_PER_HOUR"
            :content="
              visibleServices.findIndex(
                type => type === ServiceType.NUM_PACKAGE_DELIVERY
              ) > -1
                ? 'RH'
                : 'R'
            "
          />
          <div class="ml-4 text-sm">
            <div class="font-weight-bold">Reservaciones por hora</div>
            <div v-if="isUser" class="enable-service-description">
              Un conductor exclusivo para tu negocio, a partir de 4 horas en
              adelante
            </div>
          </div>
        </div>
        <div class="d-flex">
          <status-badge
            v-if="reservationService"
            class="mr-3"
            statusName="Activa"
            type="green"
          />
          <b-form-checkbox
            v-model="reservationService"
            data-test-id="checkbox-reservation"
            class="enable-services-switch"
            :disabled="loading"
            switch
          />
        </div>
      </div>

      <div
        class="border-bottom pb-3 d-flex justify-content-between align-items-center mt-3"
        v-if="
          visibleServices.findIndex(
            type => type === ServiceType.NUM_PACKAGE_DELIVERY
          ) > -1
        "
      >
        <div class="d-flex align-items-top">
          <service-type-avatar :type="ServiceType.NUM_PACKAGE_DELIVERY" />
          <div class="ml-4 text-sm">
            <div class="font-weight-bold">Rutas</div>
          </div>
        </div>
        <div class="d-flex">
          <status-badge
            v-if="routesService"
            class="mr-3"
            statusName="Activa"
            type="green"
          />
          <b-form-checkbox
            v-model="routesService"
            data-test-id="checkbox-routes"
            class="enable-services-switch"
            :disabled="loading"
            switch
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceTypeAvatar from "./ServiceTypeAvatar";
import StatusBadge from "./StatusBadge";
import ServiceType from "../constants/services/type";

export default {
  name: "EnableServices",

  components: {
    ServiceTypeAvatar,
    StatusBadge
  },

  props: {
    enabledServices: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    visibleServices: {
      type: Array,
      default: () => [
        ServiceType.NUM_MULTI_POINT,
        ServiceType.NUM_PARCEL,
        ServiceType.NUM_RENT_PER_HOUR,
        ServiceType.NUM_PACKAGE_DELIVERY
      ]
    },
    variant: {
      type: String,
      default: "admin",
      validator: val => ["admin", "user"].indexOf(val) > -1
    }
  },

  computed: {
    isUser() {
      return this.variant === "user";
    },
    companyName() {
      return this.$store.getters["companyName"];
    }
  },

  data() {
    return {
      editableServices: null,
      expressService: false,
      reservationService: false,
      parcelService: false,
      routesService: false,
      showQuickMessage: false,
      ServiceType
    };
  },

  watch: {
    enabledServices: {
      immediate: true,
      handler(enabled) {
        this.setEnabledServices(enabled);
      }
    },

    expressService(val, oldVal) {
      if (val === oldVal) return;
      if (val === true) {
        ServiceType.mulitPointTypes.forEach(type =>
          this.editableServices.add(type)
        );
      } else {
        ServiceType.mulitPointTypes.forEach(type =>
          this.editableServices.delete(type)
        );
      }
      this.updateEnabledServices(this.editableServices);
    },

    parcelService(val, oldVal) {
      if (val === oldVal) return;
      if (val === true) {
        this.editableServices.add(ServiceType.NUM_PARCEL);
      } else {
        this.editableServices.delete(ServiceType.NUM_PARCEL);
      }
      this.updateEnabledServices(this.editableServices);
    },

    reservationService(val, oldVal) {
      if (val === oldVal) return;
      if (val === true) {
        this.editableServices.add(ServiceType.NUM_RENT_PER_HOUR);
      } else {
        this.editableServices.delete(ServiceType.NUM_RENT_PER_HOUR);
      }
      this.updateEnabledServices(this.editableServices);
    },

    routesService(val, oldVal) {
      if (val === oldVal) return;
      if (val === true) {
        this.editableServices.add(ServiceType.NUM_PACKAGE_DELIVERY);
      } else {
        this.editableServices.delete(ServiceType.NUM_PACKAGE_DELIVERY);
      }
      this.updateEnabledServices(this.editableServices);
    }
  },

  created() {
    this.editableServices = new Set(this.enabledServices);
  },

  methods: {
    setEnabledServices(enabled) {
      this.editableServices = new Set(this.enabledServices);
      this.expressService = enabled
        ? enabled.findIndex(type => ServiceType.isMultiPoint(type)) > -1
        : this.expressService;
      this.parcelService = enabled
        ? enabled.findIndex(type => type === ServiceType.NUM_PARCEL) > -1
        : this.parcelService;
      this.reservationService = enabled
        ? enabled.findIndex(type => type === ServiceType.NUM_RENT_PER_HOUR) > -1
        : this.reservationService;
      this.routesService = enabled
        ? enabled.findIndex(type => type === ServiceType.NUM_PACKAGE_DELIVERY) >
          -1
        : this.routesService;
    },
    updateEnabledServices(enabledServices) {
      this.$emit("change", enabledServices);
    }
  }
};
</script>

<style lang="scss" scoped>
.enable-services-switch {
  transform: scale(130%);
}

.enable-service-subtitle {
  color: $comet;
}

.enable-service-description {
  color: $comet;
  max-width: 90%;
}
</style>
