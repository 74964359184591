export default {
  computed: {
    isFormChanged() {
      const changedFields = Object.keys(this.veeFields).some(
        key => this.veeFields[key].changed
      );
      return changedFields;
    },
    isFormDirty() {
      const changedFields = Object.keys(this.veeFields).some(
        key => this.veeFields[key].dirty
      );
      return changedFields;
    },
    isFormValid() {
      const validFields = Object.keys(this.veeFields).some(
        key => this.veeFields[key].valid !== false
      );
      return validFields;
    }
  },
  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  }
};
