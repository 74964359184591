var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex h-100 flex-column"},[_vm._t("message"),_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Modalidades de entrega")]),(_vm.isUser)?_c('div',{staticClass:"text-sm enable-service-subtitle mt-3"},[_vm._v(" Gestiona las modalidades de entrega que "+_vm._s(_vm.companyName)+" te ofrece. Para solicitar un tipo de entrega actívala presionando el switch. ")]):_vm._e()]),_c('div',[(
        _vm.visibleServices.findIndex(function (type) { return _vm.ServiceType.isMultiPoint(type); }) > -1
      )?_c('div',{staticClass:"border-bottom pb-3 d-flex justify-content-between align-items-center mt-3"},[_c('div',{staticClass:"d-flex align-items-top"},[_c('service-type-avatar',{attrs:{"type":_vm.ServiceType.NUM_SIMPLE}}),_c('div',{staticClass:"ml-4 text-sm"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Entrega Express")]),(_vm.isUser)?_c('div',{staticClass:"enable-service-description"},[_vm._v(" Nuestra promesa de entrega es en 120min, costo calculado por KM ")]):_vm._e()])],1),_c('div',{staticClass:"d-flex"},[(_vm.expressService)?_c('status-badge',{staticClass:"mr-3",attrs:{"statusName":"Activa","type":"green"}}):_vm._e(),_c('b-form-checkbox',{staticClass:"enable-services-switch",attrs:{"data-test-id":"checkbox-express","disabled":_vm.loading,"switch":""},model:{value:(_vm.expressService),callback:function ($$v) {_vm.expressService=$$v},expression:"expressService"}})],1)]):_vm._e(),(
        _vm.visibleServices.findIndex(function (type) { return type === _vm.ServiceType.NUM_PARCEL; }) >
          -1
      )?_c('div',{staticClass:"border-bottom pb-3 d-flex justify-content-between align-items-center mt-3"},[_c('div',{staticClass:"d-flex align-items-top"},[_c('service-type-avatar',{attrs:{"type":_vm.ServiceType.NUM_PARCEL}}),_c('div',{staticClass:"ml-4 text-sm"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Entrega de Guías")]),(_vm.isUser)?_c('div',{staticClass:"enable-service-description"},[_vm._v(" Recolectamos y entregamos tu paquete el mismo día o al día siguiente ")]):_vm._e()])],1),_c('div',{staticClass:"d-flex"},[(_vm.parcelService)?_c('status-badge',{staticClass:"mr-3",attrs:{"statusName":"Activa","type":"green"}}):_vm._e(),_c('b-form-checkbox',{staticClass:"enable-services-switch",attrs:{"data-test-id":"checkbox-parcels","disabled":_vm.loading,"switch":""},model:{value:(_vm.parcelService),callback:function ($$v) {_vm.parcelService=$$v},expression:"parcelService"}})],1)]):_vm._e(),(
        _vm.visibleServices.findIndex(
          function (type) { return type === _vm.ServiceType.NUM_RENT_PER_HOUR; }
        ) > -1
      )?_c('div',{staticClass:"border-bottom pb-3 d-flex justify-content-between align-items-center mt-3"},[_c('div',{staticClass:"d-flex align-items-top"},[_c('service-type-avatar',{attrs:{"type":_vm.ServiceType.NUM_RENT_PER_HOUR,"content":_vm.visibleServices.findIndex(
              function (type) { return type === _vm.ServiceType.NUM_PACKAGE_DELIVERY; }
            ) > -1
              ? 'RH'
              : 'R'}}),_c('div',{staticClass:"ml-4 text-sm"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Reservaciones por hora")]),(_vm.isUser)?_c('div',{staticClass:"enable-service-description"},[_vm._v(" Un conductor exclusivo para tu negocio, a partir de 4 horas en adelante ")]):_vm._e()])],1),_c('div',{staticClass:"d-flex"},[(_vm.reservationService)?_c('status-badge',{staticClass:"mr-3",attrs:{"statusName":"Activa","type":"green"}}):_vm._e(),_c('b-form-checkbox',{staticClass:"enable-services-switch",attrs:{"data-test-id":"checkbox-reservation","disabled":_vm.loading,"switch":""},model:{value:(_vm.reservationService),callback:function ($$v) {_vm.reservationService=$$v},expression:"reservationService"}})],1)]):_vm._e(),(
        _vm.visibleServices.findIndex(
          function (type) { return type === _vm.ServiceType.NUM_PACKAGE_DELIVERY; }
        ) > -1
      )?_c('div',{staticClass:"border-bottom pb-3 d-flex justify-content-between align-items-center mt-3"},[_c('div',{staticClass:"d-flex align-items-top"},[_c('service-type-avatar',{attrs:{"type":_vm.ServiceType.NUM_PACKAGE_DELIVERY}}),_vm._m(0)],1),_c('div',{staticClass:"d-flex"},[(_vm.routesService)?_c('status-badge',{staticClass:"mr-3",attrs:{"statusName":"Activa","type":"green"}}):_vm._e(),_c('b-form-checkbox',{staticClass:"enable-services-switch",attrs:{"data-test-id":"checkbox-routes","disabled":_vm.loading,"switch":""},model:{value:(_vm.routesService),callback:function ($$v) {_vm.routesService=$$v},expression:"routesService"}})],1)]):_vm._e()])],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-4 text-sm"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Rutas")])])}]

export { render, staticRenderFns }