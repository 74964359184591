<template>
  <z-modal
    modal-class="address-create"
    :show="show"
    title="Agregar dirección"
    size="lg"
    @update:show="$emit('update:show', $event)"
  >
    <b-row>
      <b-col>
        <z-map-expandable
          :key="zMapKey"
          :zoom="15"
          :center="mapCenter"
          :destinations="mapPin"
          :calculate-route="false"
          class="map-container"
        />
      </b-col>
      <b-col class="form-data" md="12" lg="6">
        <z-input
          id="address-name"
          v-model="formData.name"
          name="name"
          type="text"
          class="mb-3"
          label="Nombre *"
          :state="validateState('name')"
        >
        </z-input>
        <z-input
          id="address-company"
          v-model="formData.company"
          label="Empresa"
          name="company"
          type="text"
          class="mb-3"
        >
        </z-input>
        <z-input
          id="address-phone"
          v-model="formData.phone"
          name="phone"
          type="tel"
          class="mb-3"
          label="Teléfono *"
        >
        </z-input>
        <z-input-address
          v-model="formData.addressText"
          label="Dirección *"
          name="address"
          type="text"
          class="mb-3"
          required
          :allow-save="false"
          @selected="handleAutocompleteAddressChange"
        />
        <z-input
          id="address-info"
          v-model="formData.information"
          type="text"
          class="mb-3"
          name="info"
        >
          <template #label>
            <span class="d-sm-none">Información adicional</span>
            <span class="d-none d-sm-inline">
              Cruces, referencias o información del paquete
            </span>
          </template>
        </z-input>
        <b-form-checkbox id="address-default" v-model="formData.defaultOrigin">
          Dirección de origen predeterminada
        </b-form-checkbox>
        <div class="mt-3">
          <z-button
            class="float-right"
            type="button"
            :disabled="!isValidForm"
            @click="updateOrCreateAddress"
          >
            Aceptar
          </z-button>
          <z-button
            class="float-right mr-3"
            variant="secondary"
            @click="hideModal"
          >
            Cancelar
          </z-button>
        </div>
      </b-col>
    </b-row>
  </z-modal>
</template>

<script>
import ZModal from "@zubut/common/src/components/ZModal";
import ZInputAddress from "@zubut/common/src/components/ZInputAddress";
import ZMapExpandable from "@zubut/common/src/components/ZMapExpandable";
import validateStateMixin from "@/mixins/validateState";

export default {
  name: "AddressCreate",

  components: {
    ZInputAddress,
    ZMapExpandable,
    ZModal
  },

  mixins: [validateStateMixin],

  props: {
    address: {
      type: Object,
      default: () => ({
        name: "",
        company: "",
        address: "",
        phone: "",
        information: "",
        defaultOrigin: false
      })
    },
    show: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      formData: {},
      mapCenter: { lat: 20.6737777, lng: -103.4054536 },
      zMapKey: 0,
      mapPin: []
    };
  },

  computed: {
    isValidForm() {
      const validFields = Object.keys(this.veeFields).every(
        key => this.veeFields[key].valid
      );
      const validAddress =
        this.formData.address && this.formData.address.length > 0;
      return validFields && validAddress;
    }
  },

  watch: {
    address: {
      immediate: true,
      handler(newVal) {
        this.formData = { ...newVal, addressText: newVal.address };
      }
    },
    show(val) {
      if (val === true) {
        this.openedModal();
      } else {
        this.initAddress();
      }
    }
  },

  methods: {
    initAddress() {
      this.mapCenter = { lat: 20.6737777, lng: -103.4054536 };
      this.mapPin = [];
      this.formData = {
        name: "",
        company: "",
        address: "",
        addressText: "",
        phone: "",
        information: "",
        defaultOrigin: false
      };
    },

    hideModal() {
      this.$emit("update:show", false);
    },

    openedModal() {
      this.zMapKey++;
      if (this.formData.position) {
        this.mapPin[0] = { position: this.formData.position };
        this.mapCenter = this.formData.position;
      }
    },

    setCenter() {
      if (this.formData.position) {
        this.mapPin[0] = { position: this.formData.position };
        this.mapCenter = this.formData.position;
      }
    },

    handleAutocompleteAddressChange(address) {
      this.formData.address = address.address;
      this.formData.addressText = address.address;
      this.formData.position = address.position;
    },

    setDefaultOrigin(addressId, clientId) {
      console.log("Setted default origin");
      this.$store
        .dispatch("user/changeDefaultOrigin", { addressId, clientId })
        .then(() => {
          // TODO Notify user
        })
        .catch(err => {
          this.$captureError(err);
          // TODO Notify user
        });
    },

    updateOrCreateAddress() {
      this.$store
        .dispatch("user/createOrUpdateFavoriteAddress", this.formData)
        .then(address => {
          if (address.defaultOrigin) {
            this.setDefaultOrigin(address.id, address.clientId);
          }
          this.$emit(
            "address-success",
            "La dirección se ha actualizado correctamente"
          );
        })
        .catch(err => {
          this.$captureError(err);
          this.$emit(
            "address-error",
            "Ocurrió un problema al actualizar la dirección"
          );
        })
        .finally(() => {
          this.hideModal();
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.pac-container {
  z-index: 10000;
}

::v-deep .address-create {
  .form-data input {
    width: 100% !important;
  }

  .form-data .z-input {
    width: 100%;
    margin: 6px 0;
  }

  .address-modal form {
    padding: 15px;

    button {
      margin: 15px 0px;
    }

    button:last-child {
      margin-right: 15px;
    }
  }

  #addresses-map {
    height: 200px;
  }

  .map-parent {
    padding: 15px 25px !important;
  }

  .map-container {
    height: 300px;

    #osmap {
      height: 100%;
    }
  }
}

@media (min-width: 769px) {
  .address-create {
    .map-parent {
      padding: 15px 0px 0px 20px !important;
    }

    .map-container {
      height: 400px;
    }

    .map-container {
      width: 100%;
    }
  }
}
</style>
