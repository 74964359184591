<template>
  <div>
    <div>
      <p class="card-password-title m-0">Cambiar contraseña</p>
      <div class="text-sm mt-3 card-password-subtitle">
        Si cambias tu contraseña, se cerrará la sesión activa y tendrás que
        ingresar nuevamente.
      </div>
    </div>
    <div class="d-lg-flex justify-content-between flex-wrap">
      <z-input
        v-model="currentPassword"
        label="Contraseña actual"
        type="password"
        :disabled="!enableEdit"
        :state="validCurrentPassword"
        class="card-password-input mt-4"
      />
      <z-input
        v-model="newPassword"
        label="Nueva contraseña"
        type="password"
        :disabled="!enableEdit"
        :state="passwordState"
        class="card-password-input mt-4"
      />
      <z-input
        v-model="confirmPass"
        label="Confirmar contraseña"
        type="password"
        :disabled="!enableEdit"
        :state="confirmPasswordState"
        class="card-password-input mt-4"
      />
    </div>

    <div v-if="enableEdit" class="mt-4 d-lg-flex">
      <z-button
        :disabled="!validForm"
        class="z-action mr-2"
        @click="updatePassword"
      >
        Guardar cambios
      </z-button>
      <z-button class="z-action" @click="handleCancel">
        Cancelar
      </z-button>
    </div>

    <div v-else class="mt-4">
      <z-button class="z-action" @click="handleEdit">
        Editar
      </z-button>
    </div>

    <quick-message
      class="mt-3"
      with-icon
      :show.sync="hasMessage"
      :message="message"
      :type="typeOfMessage"
    />
  </div>
</template>

<script>
import DisconnectActions from "@/constants/users/disconnect-actions";

export default {
  name: "CardDetailPassword",

  props: {},
  data() {
    return {
      enableEdit: false,
      currentPassword: "",
      newPassword: "",
      confirmPass: "",
      message: "",
      typeOfMessage: "error",
      isSuccess: false,
      hasError: false
    };
  },

  computed: {
    hasMessage: {
      get() {
        return this.hasError || this.isSuccess;
      },
      set(newVal) {
        this.hasError = newVal;
        this.isSuccess = newVal;
      }
    },
    passwordState() {
      // Return null in success state for avoid boostrap success feedback

      return !this.enableEdit
        ? null
        : this.newPassword.length === 0
        ? false
        : this.newPassword.trim().length >= 6
        ? true
        : false;
    },
    confirmPasswordState() {
      // Return null in success state for avoid boostrap success feedback
      return !this.enableEdit
        ? null
        : this.confirmPass.length === 0
        ? false
        : this.confirmPass === this.newPassword && this.passwordState
        ? true
        : false;
    },
    validCurrentPassword() {
      return !this.enableEdit
        ? null
        : this.currentPassword.trim().length !== 0
        ? null
        : false;
    },
    validForm() {
      return (
        this.currentPassword.length > 0 &&
        this.newPassword.trim().length >= 6 &&
        this.newPassword === this.confirmPass &&
        this.newPassword !== this.currentPassword
      );
    },
    passwordStateMessage() {
      return this.passwordState !== null
        ? "La nueva contraseñas debe tener por lo menos tener 6 caracteres"
        : this.confirmPasswordState !== null
        ? "Las contraseñas no coinciden"
        : "";
    }
  },

  methods: {
    handleEdit() {
      this.enableEdit = true;
    },

    handleCancel() {
      this.enableEdit = false;
      this.currentPassword = "";
      this.newPassword = "";
      this.confirmPass = "";
    },

    updatePassword() {
      if (!this.validForm) {
        return;
      }
      const currentPassword = this.currentPassword;
      const newPassword = this.newPassword;
      this.$store
        .dispatch("user/dispatchUpdatePassword", {
          currentPassword,
          newPassword
        })
        .then(() => {
          const query = {};
          query.t = DisconnectActions.NUM_UPDATE_PASSWORD;
          this.$router.push({ name: "login", query });
        })
        .catch(err => {
          this.hasError = true;
          this.message = err.message;
          this.typeOfMessage = "error";
          console.log("error", err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.card-password-title {
  font-size: 14px;
  font-weight: 600;
}

.card-password-subtitle {
  color: $comet;
}

.z-action {
  width: 100%;
  margin-bottom: 10px;
}

@include media-breakpoint-up(lg) {
  .card-password-input {
    width: 48%;
    margin-bottom: 0;
  }

  .z-action {
    width: auto;
  }
}
</style>
