<template>
  <b-row class="container-payments row">
    <b-col lg="6">
      <profile-payment-wallet />
    </b-col>
    <b-col class="mt-3 mt-md-0" lg="6">
      <profile-payment-cards />
    </b-col>
  </b-row>
</template>

<script>
import ProfilePaymentCards from "./ProfilePaymentCards";
import ProfilePaymentWallet from "./ProfilePaymentWallet";

export default {
  name: "ProfilePayment",

  components: {
    ProfilePaymentCards,
    ProfilePaymentWallet
  }
};
</script>

<style lang="scss" scoped>
::v-deep #payment-info {
  p {
    margin-left: 1rem;
    font-weight: 400;
    font-size: 12px;

    #subtitle {
      color: $black;
      font-weight: 400;
    }
  }
}

.container-payments {
  p {
    font-size: 12px;
    font-weight: 600;
  }

  .title {
    font-size: 14px;
  }

  .empty-title {
    margin: 0;
  }

  .action {
    font-size: 12px;
    color: var(--primary);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .default-payment {
    color: $forest-green;
    font-weight: normal;
  }

  ::v-deep .btn-link {
    color: $dim-gray;
  }
}
</style>
