<template>
  <b-row class="z-table-pagination">
    <b-col class="d-flex justify-content-between pagination-row">
      <div class="table-total-items">
        <b>{{ firstItemInPageNumber }}</b> -
        <b>{{ lastItemInPageNumber }}</b> de
        <b>{{ totalRows }}</b>
      </div>
      <div class="table-options">
        <div class="mr-2">
          <b-form-select
            v-if="enablePerPage"
            class="form-select"
            :options="pageOptions"
            :value="perPage"
            @input="$emit('update:per-page', $event)"
          />
        </div>
        <div>
          <b-pagination
            class="my-0"
            :total-rows="totalRows"
            :per-page="perPage"
            :value="currentPage"
            @input="$emit('update:current-page', $event)"
          />
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "ZTablePagination",
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    perPage: {
      type: Number,
      default: 10
    },
    totalRows: {
      type: Number,
      required: true
    },
    enablePerPage: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      pageOptions: [5, 10, 15]
    };
  },
  computed: {
    firstItemInPageNumber() {
      return this.totalRows > 0
        ? this.currentPage * this.perPage - this.perPage + 1
        : 0;
    },
    lastItemInPageNumber() {
      const totalItemSpace = this.perPage * this.currentPage;
      if (totalItemSpace > this.totalRows) {
        if (this.currentPage > 1) {
          const totalPreviousPage = this.perPage * (this.currentPage - 1);
          return this.totalRows - totalPreviousPage + totalPreviousPage;
        }
        return this.totalRows;
      }
      return totalItemSpace;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .page-item.active .page-link {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.pagination-row {
  margin-right: 0px !important;

  .table-total-items {
    padding: 0.5rem 0.75rem;
  }

  .table-options {
    display: flex;
  }
}
</style>
