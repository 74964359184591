export default {
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      totalRows: 0
    };
  },
  computed: {
    filter() {
      return { skip: this.skip, limit: this.limit };
    },
    limit() {
      return this.perPage;
    },
    skip() {
      return this.perPage * (this.currentPage - 1);
    }
  }
};
