<template>
  <div class="z-table-head-sort" @click="sortChange" role="button">
    <slot />
    <z-icon
      name="Sort"
      size="16"
      :class="[
        'sort-icon',
        { 'order-asc': order === 'asc', 'order-desc': order === 'desc' }
      ]"
      data-test-id="asc-icon"
    />
  </div>
</template>

<script>
import ZIcon from "./ZIcon";

export default {
  name: "ZTableHeadSort",

  components: {
    ZIcon
  },

  props: {
    order: {
      type: String,
      default: "asc",
      validator: val => ["asc", "desc"].indexOf(val) > -1
    }
  },

  methods: {
    sortChange() {
      const newSort = this.order === "asc" ? "desc" : "asc";
      this.$emit("order-change", newSort);
      this.$emit("update:order", newSort);
    }
  }
};
</script>

<style lang="scss" scoped>
.z-table-head-sort {
  display: flex;
  align-items: center;
}

.sort-icon {
  margin-left: 8px;
}

.sort-icon.order-asc {
  ::v-deep path:nth-child(2) {
    opacity: 0.35;
  }
}

.sort-icon.order-desc {
  ::v-deep path:first-child {
    opacity: 0.35;
  }
}
</style>
