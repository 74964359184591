<template>
  <z-badge
    class="service-type-badge"
    data-test-id="content"
    :style="{ backgroundColor: backgroundColor }"
  >
    {{ displayContent }}
  </z-badge>
</template>

<script>
import ServiceType from "../constants/services/type";
import ZBadge from "./ZBadge";

export default {
  name: "ServiceTypeBadge",

  components: {
    ZBadge
  },

  props: {
    type: {
      type: Number,
      required: false,
      default: null
    },
    content: {
      type: String,
      required: false,
      validator: value => {
        return value.length > 0 && value.length <= 2;
      },
      default: null
    },
    color: {
      type: String,
      required: false,
      default: null
    }
  },

  data() {
    return {
      defaultColor: "red",
      defaultContent: "S",
      colorTypeMatch: {
        [ServiceType.NUM_SIMPLE]: "#ED6C5A",
        [ServiceType.NUM_MULTI_POINT]: "#ED6C5A",
        [ServiceType.NUM_RENT_PER_HOUR]: "#0036A1",
        [ServiceType.NUM_DYNAMIC]: "#ED6C5A",
        [ServiceType.NUM_PACKAGE_DELIVERY]: "#333333",
        [ServiceType.NUM_BATCH]: "#E10098",
        [ServiceType.NUM_PARCEL_SEND]: "#6655f6",
        [ServiceType.NUM_PARCEL_DELIVERY]: "#6655f6",
        [ServiceType.NUM_PARCEL_RECOLLECT]: "#6655f6",
        [ServiceType.NUM_PARCEL_PICKUP]: "#6655f6"
      },
      contentTypeMatch: {
        [ServiceType.NUM_SIMPLE]: "Express",
        [ServiceType.NUM_MULTI_POINT]: "Express",
        [ServiceType.NUM_RENT_PER_HOUR]: ServiceType.RENT_PER_HOUR,
        [ServiceType.NUM_DYNAMIC]: "Express",
        [ServiceType.NUM_PACKAGE_DELIVERY]: "Ruta",
        [ServiceType.NUM_BATCH]: "Lote",
        [ServiceType.NUM_PARCEL_SEND]: "Guía",
        [ServiceType.NUM_PARCEL_DELIVERY]: "Guía",
        [ServiceType.NUM_PARCEL_RECOLLECT]: "Guía",
        [ServiceType.NUM_PARCEL_PICKUP]: "Guía"
      }
    };
  },

  computed: {
    displayContent() {
      if (this.content != null) return this.content;
      else if (this.type != null) return this.contentTypeMatch[this.type];
      return this.defaultContent;
    },
    backgroundColor() {
      if (this.color != null) return this.color;
      if (this.type != null) return this.colorTypeMatch[this.type];
      return this.defaultColor;
    }
  }
};
</script>

<style lang="scss" scoped>
.service-type-badge {
  background-color: red;
  border-radius: 6px;
  color: white;
  font-family: Montserrat;
  font-size: 0.85rem;
  font-weight: 500 !important;
  padding: 8px 6px !important;
}
</style>
