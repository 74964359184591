<template>
  <div class="card p-4 rounded">
    <div class="d-flex justify-content-between align-items-center">
      <p class="title ">Mis tarjetas</p>
      <z-button
        class="my-3 mt-md-0"
        variant="primary-alt"
        size="sm"
        @click="handleShowCreditCardModal"
      >
        <div class="d-flex align-items-center">
          <z-icon class="mr-2" name="Add" variant="active" size="22" />
          Agregar tarjeta
        </div>
      </z-button>
    </div>
    <div>
      <quick-message
        class="mb-3"
        :show.sync="showCreditCartMessage"
        with-icon
        :message="message"
        :type="typeOfMessage"
      />
      <!-- placeholder item -->
      <div v-show="!hasCreditCards" class="border rounded mb-3 py-2 px-3 w-100">
        <z-payment-item
          v-if="loadingCreditCards"
          :loading="loadingCreditCards"
          title="Loading"
          subtitle="Item"
          class="p-2"
        />
        <p v-else class="empty-title px-2 py-3">
          No tienes tarjetas registradas
        </p>
      </div>
      <div
        v-for="(creditCard, index) in creditCards"
        :key="`d-${creditCard.id}`"
      >
        <div class="border rounded mb-3 py-2 px-3 w-100">
          <z-payment-item
            class="p-2"
            :icon="defPaymentIcon(creditCard)"
            :subtitle="defPaymentSubtitle(creditCard)"
            :title="defPaymentTitle(creditCard)"
          >
            <template v-slot:icon-left>
              <div>
                <b-form-checkbox
                  class="checkbox"
                  size="lg"
                  :checked="creditCard.default"
                  @change="setDefaultCreditCard($event, index)"
                />
              </div>
            </template>

            <template v-slot:icon-right class="d-flex align-items-center">
              <div class="d-flex align-items-center">
                <status-badge
                  v-if="creditCard.default"
                  status-name="Predeterminado"
                  type="green"
                />
                <b-dropdown
                  menu-class=""
                  variant="link"
                  size="sm"
                  no-caret
                  dropleft
                  lazy
                >
                  <template slot="button-content">
                    <font-awesome-icon icon="ellipsis-h" />
                  </template>
                  <b-dropdown-item
                    @click.stop="showDeleteCreditCardConfirmationModal(index)"
                  >
                    Eliminar
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </z-payment-item>
        </div>
      </div>
    </div>
    <add-credit-card
      :show="showCreditCardModal"
      @credit-card-added="creditCardAdded"
      @hidden="showCreditCardModal = false"
    />
    <confirmation-dialog
      centered
      :title="`Eliminar la tarjeta con terminación ${last4}`"
      :show="showConfirmationModal"
      @accept="deleteCreditCard()"
      @cancel="hideConfirmationModal()"
      @hidden="hideConfirmationModal()"
    >
      <p>¿Seguro que deseas eliminar la tarjeta?</p>
    </confirmation-dialog>
  </div>
</template>

<script>
import lazyLoad from "@/app/components/lazyLoad";
import ConfirmationDialog from "@/app/components/ConfirmationDialog";
import ZPaymentItem from "@/app/components/ZPaymentItem";
import StatusBadge from "@zubut/common/src/components/StatusBadge";

const AddCreditCard = () => lazyLoad(import("@/app/components/AddCreditCard"));

export default {
  name: "ProfilePaymentCards",

  components: {
    AddCreditCard,
    ConfirmationDialog,
    ZPaymentItem,
    StatusBadge
  },

  data() {
    return {
      loadingCreditCards: false,
      loadingAction: false,
      showCreditCardModal: false,
      showConfirmationModal: false,
      showCreditCartMessage: false,
      activeCreditCard: null,
      message: "",
      typeOfMessage: "success"
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    creditCards() {
      return this.user?.creditCards ?? [];
    },
    hasCreditCards() {
      return this.creditCards.length !== 0;
    },
    last4() {
      return this.activeCreditCard?.last4 ?? "";
    }
  },

  beforeMount() {
    // Only load credit cards when credit card array is empty
    if (this.creditCards.length === 0) {
      this.getCreditCards();
    }
  },

  methods: {
    getCreditCards() {
      this.loadingCreditCards = true;
      this.$store.dispatch("user/getCreditCards").finally(() => {
        this.loadingCreditCards = false;
      });
    },

    defPaymentIcon(item) {
      if (item?.brand) {
        return item.brand.toLowerCase();
      } else {
        return "wallet";
      }
    },

    defPaymentTitle(item) {
      if (item != null) {
        let brand = "Tarjeta";
        switch (item.brand.toLowerCase()) {
          case "mc":
            brand = "Mastercard";
            break;
          case "visa":
            brand = "Visa";
            break;
          case "american_express":
            brand = "American express";
            break;
        }
        return `${brand} **** ${item.last4}`;
      } else {
        return "***";
      }
    },

    defPaymentSubtitle: function(item) {
      if (item != null) {
        return `${item.exp_month}/${item.exp_year}`;
      } else {
        return `***`;
      }
    },

    handleShowCreditCardModal() {
      this.showCreditCardModal = true;
    },

    creditCardAdded(event) {
      this.addCreditCardModalKey++;
      this.showCreditCartMessage = true;
      this.message = "Tarjeta agregada con éxito";
    },

    setDefaultCreditCard(event, index) {
      if (this.loadingAction) return;
      this.loadingAction = true;
      this.$store
        .dispatch("user/setDefaultCreditCard", this.creditCards[index].id)
        .then(() => {
          this.showCreditCartMessage = true;
          this.typeOfMessage = "success";
          this.message = "Se actualizo tu método de pago predeterminado";
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loadingAction = false;
        });
    },

    showDeleteCreditCardConfirmationModal(index) {
      this.activeCreditCard = this.creditCards[index];
      this.showConfirmationModal = true;
    },

    hideConfirmationModal() {
      this.showConfirmationModal = false;
    },

    deleteCreditCard(index) {
      if (this.loadingAction) return;
      this.hideConfirmationModal();
      this.loadingAction = true;
      this.$store
        .dispatch("user/deleteCreditCard", this.activeCreditCard)
        .then(() => {
          this.showCreditCartMessage = true;
          this.typeOfMessage = "success";
          this.message = "Se ha eliminado tu tarjeta";
        })
        .catch(err => {
          this.showCreditCartMessage = true;
          this.typeOfMessage = "error";
          this.message = "No se pudo eliminar tu tarjeta";
        })
        .finally(() => {
          this.loadingAction = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep #payment-info {
  p {
    margin-left: 1rem;
    font-weight: 400;
    font-size: 12px;

    #subtitle {
      color: $black;
      font-weight: 400;
    }
  }
}

.container-payments {
  p {
    font-size: 12px;
    font-weight: 600;
  }

  .title {
    font-size: 14px;
  }

  .empty-title {
    margin: 0;
  }

  .action {
    font-size: 12px;
    color: var(--primary);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .default-payment {
    color: $forest-green;
    font-weight: normal;
  }

  ::v-deep .btn-link {
    color: $dim-gray;
  }
}
</style>
