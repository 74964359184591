<template>
  <div class="d-lg-flex justify-content-between mt-4">
    <div class="profile-account-flex">
      <card-detail-info
        :id="user.id"
        class="card p-4 mb-3 rounded"
        :business-line="user.businessLine"
        :type="user.type"
        :name.sync="user.name"
        :contact.sync="user.contact"
        :phone.sync="user.phone"
        :email.sync="user.email"
      />
      <card-detail-password class="card p-4 mb-3 rounded pass" />
    </div>
    <div class="profile-account-flex">
      <card-detail-enabled-services class="card p-4 rounded rate" />
    </div>
  </div>
</template>

<script>
import CardDetailInfo from "./CardDetailInfo";
import CardDetailPassword from "./CardDetailPassword";
import CardDetailEnabledServices from "./CardDetailEnabledServices.vue";

export default {
  name: "UserDetailProfile",
  components: {
    CardDetailEnabledServices,
    CardDetailInfo,
    CardDetailPassword
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
@include media-breakpoint-up(lg) {
  .profile-account-flex {
    width: 49%;
  }
}

.card {
  height: auto !important;
}
</style>
