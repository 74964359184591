<template>
  <div class="map-container">
    <button class="modal-button" @click="showModal = true">
      <z-icon name="Expand" size="18" color="#656B7f" />
    </button>
    <z-map
      class="w-100 h-100"
      :destinations="destinations"
      :mark-origin="true"
      :calculate-route="calculateRoute"
      @rate-change="rateChange"
    />
    <z-modal :show.sync="showModal" centered bodyClass="p-2">
      <div class="modal-map">
        <z-map
          v-if="showModal"
          class="w-100 h-100"
          :destinations="destinations"
          :calculate-route="calculateRoute"
          :mark-origin="true"
        />
      </div>
    </z-modal>
  </div>
</template>

<script>
import ZMap from "@zubut/common/src/components/ZMap";
import ZModal from "@zubut/common/src/components/ZModal";

export default {
  name: "ZMapExpandable",

  components: {
    ZMap,
    ZModal
  },

  props: {
    calculateRoute: {
      type: Boolean,
      default: false
    },
    destinations: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      showModal: false
    };
  },

  methods: {
    rateChange({ km, minutes }) {
      this.$emit("rate-change", { km, minutes });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/variables/colors.scss";

.map-container {
  width: 100%;
  height: 260px;
  border-radius: 1em;
  border: 1px solid $solitude;
  position: relative;
}

.modal-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  margin: 1.5rem;
  z-index: 401;
  box-sizing: border-box;
  height: 37px;
  width: 37px;
  border: 1px solid $solitude;
  border-radius: 6px;
  background-color: $white;
  box-shadow: 0 2px 4px 0 rgba($comet, 0.5);
}

.modal-map {
  height: 90vh;
  max-height: 800px;
}
</style>
