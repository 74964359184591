<template>
  <div>
    <quick-message
      class="mb-2 mx-2"
      with-icon
      :show.sync="hasMessage"
      :message="message"
      :type="hasError ? 'error' : 'success'"
    />
    <div class="mt-4 card p-4 rounded-lg">
      <quick-message
        v-if="missingBillingData"
        class="mb-3"
        show
        with-icon
        message="No tienes datos de facturación."
      />
      <quick-message
        v-if="billingFromAccount"
        class="mb-3"
        show
        with-icon
        message="Solo lectura. Datos de facturación de la cuenta."
      />
      <b-form class="z-grid-page z-form">
        <div class="invoice-data">
          <p>Datos de facturación</p>
          <b-form-group
            label="Nombre Fiscal"
            label-for="user-billing-name"
            label-size="sm"
            :state="veeErrors.first('legal-name') ? false : null"
            :invalid-feedback="veeErrors.first('legal-name')"
          >
            <b-form-input
              id="user-billing-name"
              v-model="mutableBillingData.billingName"
              v-validate="'required'"
              :readonly="disabled"
              type="text"
              class="form-control"
              data-vv-as="Nombre Fiscal"
              name="legal-name"
              trim
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            label="RFC"
            label-for="user-rfc"
            label-size="sm"
            :description="!rfcState ? 'Ingresa tu RFC p.e: AAA010101AAA' : ''"
            :state="rfcState"
          >
            <b-form-input
              id="user-rfc"
              v-model="mutableBillingData.rfc"
              :readonly="disabled"
              maxlength="13"
              type="text"
              class="form-control"
              name="rfc"
              trim
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            label="Dirección de correo adicional 1"
            label-for="user-alternate-email-1"
            label-size="sm"
            :state="alternateEmail1State"
            :invalid-feedback="alternateEmail1InvalidFeedback"
          >
            <b-form-input
              id="user-alternate-email-1"
              v-model="mutableBillingData.alternateEmail1"
              v-validate="'email'"
              :readonly="disabled"
              type="text"
              class="form-control"
              data-vv-as="Correo alterno 1"
              name="alternateEmail1"
              trim
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            label="Dirección de correo adicional 2"
            label-for="user-alternate-email-2"
            label-size="sm"
            :state="alternateEmail2State"
            :invalid-feedback="alternateEmail2InvalidFeedback"
          >
            <b-form-input
              id="user-alternate-email-2"
              v-model="mutableBillingData.alternateEmail2"
              v-validate="'email'"
              :readonly="disabled"
              type="text"
              class="form-control"
              data-vv-as="Correo alterno 2"
              name="alternateEmail2"
              trim
            >
            </b-form-input>
          </b-form-group>
        </div>
        <div class="personal-data">
          <p>Dirección de facturación</p>
          <div
            class="d-flex justify-content-between flex-column flex-column flex-sm-row"
          >
            <b-form-group
              class="flex-grow-1 m-0 mr-sm-2"
              label="País"
              label-for="user-billing-country"
              label-size="sm"
              :state="veeErrors.first('user-billing-country') ? false : null"
              :invalid-feedback="veeErrors.first('user-billing-country')"
            >
              <b-form-input
                id="user-billing-country"
                v-model="mutableBillingData.country"
                v-validate="'required'"
                :readonly="true"
                type="text"
                class="form-control"
                data-vv-as="País"
                name="user-billing-country"
                trim
                required
              />
            </b-form-group>
            <b-form-group
              class="flex-grow-1 m-0 mt-2 mt-sm-0 ml-sm-2"
              label="Código Postal"
              label-for="user-billing-zip-code"
              label-size="sm"
              :description="
                !veeErrors.first('user-billing-zip-code') ? '5 dígitos' : ''
              "
              :state="veeErrors.first('user-billing-zip-code') ? false : null"
              :invalid-feedback="veeErrors.first('user-billing-zip-code')"
            >
              <b-form-input
                id="user-billing-zip-code"
                v-model="mutableBillingData.zipcode"
                v-validate="'required|length:5|numeric'"
                :readonly="disabled"
                type="text"
                class="form-control"
                data-vv-as="Código Postal"
                name="user-billing-zip-code"
                trim
                required
              />
            </b-form-group>
          </div>
          <b-form-group
            label="Calle"
            label-for="user-billing-street"
            label-size="sm"
            :state="veeErrors.first('billing-street') ? false : null"
            :invalid-feedback="veeErrors.first('billing-street')"
          >
            <b-form-input
              id="user-billing-street"
              v-model="mutableBillingData.street"
              v-validate="'required'"
              :readonly="disabled"
              type="text"
              class="form-control"
              data-vv-as="Calle"
              name="billing-street"
              trim
              required
            >
            </b-form-input>
          </b-form-group>
          <div
            class="d-flex justify-content-between flex-column flex-sm-row mb-2"
          >
            <b-form-group
              class="flex-grow-1 m-0 mr-sm-2"
              label="No. Exterior"
              label-for="user-billing-number"
              label-size="sm"
              :state="veeErrors.first('user-billing-number') ? false : null"
              :invalid-feedback="veeErrors.first('user-billing-number')"
            >
              <b-form-input
                id="user-billing-number"
                v-model="mutableBillingData.numExt"
                v-validate="'required'"
                :readonly="disabled"
                type="text"
                class="form-control"
                data-vv-as="Número"
                name="user-billing-number"
                trim
                required
              />
            </b-form-group>
            <b-form-group
              class="flex-grow-1 m-0 mt-2 mt-sm-0 ml-sm-2"
              label="No. Interior"
              label-for="user-billing-number-optional"
              label-size="sm"
            >
              <b-form-input
                id="user-billing-number-optional"
                v-model="mutableBillingData.numInt"
                :readonly="disabled"
                type="text"
                class="form-control"
                name="user-billing-number-optional"
                placeholder="(Opcional)"
                trim
              />
            </b-form-group>
          </div>
          <b-form-group
            label="Colonia"
            label-for="user-billing-neighborhood"
            label-size="sm"
            :state="veeErrors.first('user-billing-neighborhood') ? false : null"
            :invalid-feedback="veeErrors.first('user-billing-neighborhood')"
          >
            <b-form-select
              v-model="mutableBillingData.neighborhood"
              v-validate="'required'"
              :disabled="disabled"
              :options="neighborhoodOptions"
              data-vv-as="Colonia"
              name="user-billing-neighborhood"
              required
              trim
            />
          </b-form-group>
          <b-form-group
            label="Ciudad"
            label-for="user-billing-city"
            label-size="sm"
            :state="veeErrors.first('user-billing-city') ? false : null"
            :invalid-feedback="veeErrors.first('user-billing-city')"
          >
            <b-form-input
              id="user-billing-city"
              v-model="mutableBillingData.city"
              v-validate="'required'"
              :disabled="!editAutocompleteInputs"
              type="text"
              class="form-control"
              data-vv-as="Ciudad"
              name="user-billing-city"
              trim
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            label="Estado"
            label-for="user-billing-state"
            label-size="sm"
            :state="veeErrors.first('user-billing-state') ? false : null"
            :invalid-feedback="veeErrors.first('user-billing-state')"
          >
            <b-form-input
              id="user-billing-state"
              v-model="mutableBillingData.state"
              v-validate="'required'"
              :disabled="!editAutocompleteInputs"
              type="text"
              class="form-control"
              data-vv-as="Estado"
              name="user-billing-state"
              trim
              required
            >
            </b-form-input>
          </b-form-group>
        </div>
      </b-form>
    </div>
    <div class="my-4 w-100 mx-0 mx-sm-5 d-flex flex-column flex-sm-row">
      <z-button
        v-show="disabled"
        class="z-action w-auto mr-0 mr-sm-3"
        @click="disabled = !disabled"
      >
        Editar
      </z-button>

      <z-button
        v-show="!disabled"
        variant="secondary"
        class="z-action w-auto mr-0 mr-sm-3"
        @click="cancelEdit"
      >
        Cancelar
      </z-button>
      <z-button
        v-show="!disabled"
        :loading="isUpdatingBillingData"
        :disabled="disableSaveButton"
        class="z-action w-auto mt-3 mt-sm-0"
        @click="updateBillingData"
      >
        Guardar cambios
      </z-button>
    </div>
  </div>
</template>

<script>
import _debounce from "lodash/debounce";
import notifyMixin from "@/mixins/notify";
import validateStateMixin from "@/mixins/validateState";
import Clients from "@/services/clients";

export default {
  name: "CardDetailBillingData",

  mixins: [notifyMixin("notifications"), validateStateMixin],

  props: {
    clientId: {
      type: String,
      required: true
    },
    userEmail: {
      type: String,
      default: ""
    },
    additional: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      disabled: true,
      searched: false,
      neighborhoodOptions: [],
      billingData: {
        id: "",
        city: "",
        country: "México",
        numExt: "",
        numInt: "",
        state: "",
        street: "",
        zipcode: "",
        neighborhood: "",
        billingName: "",
        rfc: "",
        alternateEmail1: "",
        alternateEmail2: ""
      },
      mutableBillingData: {
        city: "",
        country: "",
        numExt: "",
        numInt: "",
        state: "",
        street: "",
        zipcode: "",
        neighborhood: "",
        billingName: "",
        rfc: "",
        alternateEmail1: "",
        alternateEmail2: ""
      },
      isUpdatingBillingData: false,
      hasChanges: false,
      message: "",
      isSuccess: false,
      hasError: false
    };
  },

  computed: {
    safeAdditional() {
      if (this.additional == null || this.additional.alternateEmails == null)
        return {
          alternateEmails: []
        };
      return this.additional;
    },
    alternateEmail1() {
      if (this.safeAdditional.alternateEmails.length < 1) return "";
      return this.safeAdditional.alternateEmails[0];
    },
    alternateEmail2() {
      if (this.safeAdditional.alternateEmails.length < 2) return "";
      return this.safeAdditional.alternateEmails[1];
    },
    alternateEmail1State() {
      if (
        this.veeErrors.first("alternateEmail1") ||
        (this.mutableBillingData.alternateEmail1.length > 0 &&
          this.mutableBillingData.alternateEmail1 === this.userEmail)
      )
        return false;
      return true;
    },
    alternateEmail1InvalidFeedback() {
      if (this.veeErrors.first("alternateEmail1")) return "Correo inválido";
      if (
        this.mutableBillingData.alternateEmail1.length > 0 &&
        this.mutableBillingData.alternateEmail1 === this.userEmail
      )
        return "El correo adicional debe ser distinto al de la cuenta";
      return "";
    },
    alternateEmail2State() {
      if (
        this.veeErrors.first("alternateEmail2") ||
        (this.mutableBillingData.alternateEmail1.length > 0 &&
          this.mutableBillingData.alternateEmail2.length > 0 &&
          this.mutableBillingData.alternateEmail1 ===
            this.mutableBillingData.alternateEmail2)
      )
        return false;
      if (
        this.mutableBillingData.alternateEmail2.length > 0 &&
        this.mutableBillingData.alternateEmail2 === this.userEmail
      )
        return false;
      return true;
    },
    alternateEmail2InvalidFeedback() {
      if (this.veeErrors.first("alternateEmail2")) return "Correo inválido";
      if (
        this.mutableBillingData.alternateEmail2.length > 0 &&
        this.mutableBillingData.alternateEmail2 === this.userEmail
      )
        return "El correo adicional debe ser distinto al de la cuenta";
      if (
        this.mutableBillingData.alternateEmail1.length > 0 &&
        this.mutableBillingData.alternateEmail2.length > 0 &&
        this.mutableBillingData.alternateEmail1 ===
          this.mutableBillingData.alternateEmail2
      )
        return "El correo adicional no puede ser el mismo que el anterior";
      return "";
    },
    updatedEmails() {
      const emails = [
        this.mutableBillingData.alternateEmail1,
        this.mutableBillingData.alternateEmail2
      ];
      return emails;
    },
    rfcState() {
      return this.mutableBillingData.rfc.length === 0
        ? null
        : this.validateRfc(this.mutableBillingData.rfc);
    },
    disableSaveButton() {
      return (
        !this.isFormValid ||
        this.isUpdatingBillingData ||
        !this.isFormDirty ||
        !this.rfcState ||
        !this.alternateEmail1State ||
        !this.alternateEmail2State
      );
    },
    missingBillingData() {
      return !this.billingData || this.billingData.billingName === "";
    },
    billingFromAccount() {
      if (!this.missingBillingData) {
        return this.mutableBillingData.accountsId != null;
      }
      return false;
    },
    editAutocompleteInputs() {
      if (this.disabled) return false;
      return this.searched && this.neighborhoodOptions.length <= 0;
    },
    hasMessage: {
      get() {
        return this.hasError || this.isSuccess;
      },
      set(newVal) {
        this.hasError = newVal;
        this.isSuccess = newVal;
      }
    }
  },

  watch: {
    billingData: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.resetBillingData();
        }
      }
    },

    mutableBillingData: {
      deep: true,
      handler() {
        this.hasChanges = true;
      }
    },

    "mutableBillingData.zipcode": {
      handler(newVal, oldVal) {
        if (newVal.length > 4) {
          this.dispatchSearch();
        } else {
          if (oldVal.length > 4) {
            this.searched = false;
            this.clearAutocompleteInputs();
          }
        }
      }
    }
  },

  mounted() {
    this.getBillingData();
    this.dispatchSearch = _debounce(this.getInfoByZipCode, 700);
  },

  methods: {
    validateRfc(rfc) {
      return rfc.length === 12 || rfc.length == 13;
    },

    getBillingData() {
      Clients.getBillingData(this.clientId)
        .then(res => {
          if (res) {
            this.billingData = res;
            this.resetBillingData();
            this.neighborhoodOptions = [this.billingData.neighborhood];
          }
        })
        .catch(err => this.$captureError(err))
        .finally(() => {
          this.loading = false;
        });
    },

    getInfoByZipCode() {
      const zipCode = this.mutableBillingData.zipcode;
      this.$store
        .dispatch("utils/getNeighborhoodByZipCode", zipCode)
        .then(res => {
          this.mutableBillingData.state = res.state;
          this.mutableBillingData.city = res.city;
          this.neighborhoodOptions = res.neighborhoods;
        })
        .catch(err => {
          this.clearAutocompleteInputs();
        })
        .finally(() => (this.searched = true));
    },

    updateBillingData() {
      this.disabled = true;
      this.isUpdatingBillingData = true;
      const updatePromises = [
        this.$store.dispatch("user/dispatchBillingData", {
          clientId: this.clientId,
          data: this.mutableBillingData,
          billingDataClientId: this.billingData.clientId
        })
      ];
      if (this.updatedEmails.length > 0) {
        updatePromises.push(
          this.$store.dispatch("user/updateAdditional", {
            clientId: this.clientId,
            data: {
              alternateEmails: this.updatedEmails.filter(
                email => email.length > 0
              )
            }
          })
        );
      }
      Promise.all(updatePromises)
        .then(([updatedBillingData, _]) => {
          this.$emit("updated", updatedBillingData);
          this.message = "Datos de facturación actualizados con éxito";
          this.isSuccess = true;
        })
        .catch(err => {
          this.resetBillingData();
          console.error(err);
          this.hasError = true;
          this.message = "No se ha podido realizar el cambio";
        })
        .finally(() => {
          this.isUpdatingBillingData = false;
          this.$validator.reset();
        });
    },

    cancelEdit() {
      this.resetBillingData();
      this.$validator.reset();
      this.disabled = true;
      this.hasChanges = false;
      this.searched = false;
    },
    resetBillingData() {
      this.searched = false;
      this.mutableBillingData.id = this.billingData.id;
      this.mutableBillingData.city = this.billingData.city;
      this.mutableBillingData.country = this.billingData.country || "México";
      this.mutableBillingData.state = this.billingData.state;
      this.mutableBillingData.street = this.billingData.street;
      this.mutableBillingData.zipcode = this.billingData.zipcode;
      this.mutableBillingData.neighborhood = this.billingData.neighborhood;
      this.mutableBillingData.billingName = this.billingData.billingName;
      this.mutableBillingData.rfc = this.billingData.rfc;
      this.mutableBillingData.numExt = this.billingData.numExt || "";
      this.mutableBillingData.numInt = this.billingData.numInt || "";
      this.mutableBillingData.alternateEmail1 = this.alternateEmail1;
      this.mutableBillingData.alternateEmail2 = this.alternateEmail2;
    },
    clearAutocompleteInputs() {
      this.mutableBillingData.state = "";
      this.mutableBillingData.city = "";
      this.neighborhoodOptions = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  color: $black;
  border: 1px solid $gainsboro;
}

.z-form {
  ::v-deep .col-form-label-sm {
    font-size: 12px;
    margin-bottom: 4px;
    padding: 0;
  }

  input {
    max-width: 460px;
    border: 1px solid $gainsboro;
  }

  select {
    max-width: 460px;
    border: 1px solid $gainsboro;
  }

  ::v-deep .form-control::placeholder {
    font-size: 12px;
    color: $dim-gray;
  }

  .flex-column {
    max-width: 460px;
  }
}

.z-action {
  height: 40px;
  width: 100px;
  font-weight: 500;
}

.z-grid-page {
  display: grid;
  grid-gap: 2em;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;

  p:first-child {
    font-size: 14px;
    font-weight: 600;
  }
}

@media only screen and (min-width: 768px) {
  .z-grid-page {
    grid-gap: 1.5rem;
    grid-template-rows: auto;
    grid-template-columns: repeat(2, 1fr);

    .invoice-data {
      grid-column: 1;
    }

    .personal-data {
      grid-column: 2;
    }
  }
}
</style>
