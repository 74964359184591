<template>
  <b-dropdown
    menu-class="z-actions-menu"
    variant="link"
    size="md"
    no-caret
    dropleft
    lazy
  >
    <template v-slot:button-content>
      <z-icon name="More" />
    </template>
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </b-dropdown>
</template>

<script>
import ZIcon from "./ZIcon";

export default {
  name: "ZActionsMenu",

  components: {
    ZIcon
  }
};
</script>

<style lang="scss" scoped>
::v-deep .z-actions-menu {
  border-radius: 10px;
  border: 0px;
  box-shadow: 0 3px 9px 1px rgba(29, 34, 63, 0.2);
  padding: 0.5rem;
}
</style>
