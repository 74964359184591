<template>
  <div>
    <b-table
      class="z-table"
      responsive="lg"
      show-empty
      thead-class="text-dark"
      :tbody-tr-class="{ 'z-table-row-hover': hover }"
      :items="items"
      :per-page="perPage"
      :fields="fields"
      :busy="loading"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <loading-spinner />
        </div>
      </template>
      <template v-slot:empty>
        <div data-test-id="empty-message" class="empty-message">
          {{ emptyMessage }}
        </div>
      </template>
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "ZTable",

  props: {
    emptyMessage: {
      type: String,
      default: "No hay nada aquí"
    },
    hover: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    perPage: {
      type: Number,
      deafault: false
    },
    loading: {
      type: Boolean,
      deafault: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/variables/colors.scss";

.z-table {
  overflow-x: auto;
}

::v-deep .z-table {
  thead th,
  tbody td {
    padding-left: 0;
  }

  thead th {
    border-top: none !important;
    color: $nero;
    font-size: 13px;
    font-weight: 600;

    svg {
      color: $dim-gray;
    }
  }

  td {
    font-size: 12px;
    height: 53px;
    vertical-align: middle;
  }

  .b-table-empty-row td {
    padding-top: 1.14rem;
    padding-left: 0;
    padding-right: 0;
  }

  .z-dropdown {
    .z-dropdown-icon.filtered {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: -2px;
        right: -5px;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        border: 1px solid $white;
        background: $zubut-blue;
      }
    }
  }
}

::v-deep .z-table-row-hover {
  &:hover {
    background-color: rgba($royal-blue, 0.06) !important;
    cursor: pointer;
  }
}

.empty-message {
  border-radius: 6px;
  padding: 1.35rem;
  text-align: center;
  background-color: #f4f6f9;
  color: $comet;
  font-size: 1rem;
}

@media (max-width: 767px) {
  ::v-deep .table-responsive .dropdown-menu {
    position: static !important;
  }
}
@media (max-width: 990px) {
  .z-table {
    display: block;
  }
}
@media (min-width: 768px) {
  ::v-deep .table-responsive {
    overflow: inherit;
  }
}
</style>
