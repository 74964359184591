import DropdownOption from "@zubut/common/src/models/dropdown-option";

const CREATED = "Creado";
const ACTIVE = "Activo";
const ONSITE = "Sitio";
const PICKUP = "Recolectado";
const ONROUTE = "En Ruta";
const INWAREHOUSE = "En Bodega";
const COMPLETE = "Completado";
const CANCEL = "Cancelado";
const DELIVER = "Entregado";
const RETURNED = "Retornado";
const COLLECTION_ATTEMPT = "Intento de recolección";

const NUM_CREATED = 0;
const NUM_ACTIVE = 1;
const NUM_ONSITE = 2;
const NUM_PICKUP = 3;
const NUM_ONROUTE = 4;
const NUM_INWAREHOUSE = 5;
const NUM_COMPLETE = 6;
const NUM_CANCEL = 7;
const NUM_DELIVER = 8;
const NUM_RETURNED = 9;
const NUM_COLLECTION_ATTEMPT = 10;

const get: any = {};
get[DELIVER] = NUM_DELIVER;
get[CREATED] = NUM_CREATED;
get[ACTIVE] = NUM_ACTIVE;
get[ONSITE] = NUM_ONSITE;
get[PICKUP] = NUM_PICKUP;
get[ONROUTE] = NUM_ONROUTE;
get[INWAREHOUSE] = NUM_INWAREHOUSE;
get[COMPLETE] = NUM_COMPLETE;
get[CANCEL] = NUM_CANCEL;
get[RETURNED] = NUM_RETURNED;
get[COLLECTION_ATTEMPT] = NUM_COLLECTION_ATTEMPT;
get[NUM_DELIVER] = DELIVER;
get[NUM_CREATED] = CREATED;
get[NUM_ACTIVE] = ACTIVE;
get[NUM_ONSITE] = ONSITE;
get[NUM_PICKUP] = PICKUP;
get[NUM_ONROUTE] = ONROUTE;
get[NUM_INWAREHOUSE] = INWAREHOUSE;
get[NUM_COMPLETE] = COMPLETE;
get[NUM_CANCEL] = CANCEL;
get[NUM_RETURNED] = RETURNED;
get[NUM_COLLECTION_ATTEMPT] = COLLECTION_ATTEMPT;

const optionsHistory: DropdownOption[] = [
  { value: NUM_CREATED, text: CREATED },
  { value: NUM_COMPLETE, text: COMPLETE },
  { value: NUM_CANCEL, text: CANCEL },
  { value: NUM_DELIVER, text: DELIVER },
  { value: NUM_RETURNED, text: RETURNED }
];

export default {
  get,
  optionsHistory,
  DELIVER,
  CREATED,
  ACTIVE,
  ONSITE,
  PICKUP,
  ONROUTE,
  INWAREHOUSE,
  COMPLETE,
  CANCEL,
  RETURNED,
  COLLECTION_ATTEMPT,
  NUM_CREATED,
  NUM_ACTIVE,
  NUM_ONSITE,
  NUM_PICKUP,
  NUM_ONROUTE,
  NUM_INWAREHOUSE,
  NUM_COMPLETE,
  NUM_CANCEL,
  NUM_DELIVER,
  NUM_RETURNED,
  NUM_COLLECTION_ATTEMPT
};
