import currency from "currency.js";
import { roundTo } from "./numbers";

const moneyFormat = (value: any) =>
  currency(value, { symbol: "$", separator: ",", decimal: "." });

export function formatMoney(amount: number): string {
  return moneyFormat(amount).format();
}

export function taxSubtotal(subtotal: number): number | null {
  if (subtotal == null) return null;
  return roundTo(subtotal * 1.16, 2);
}
