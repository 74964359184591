<template>
  <div class="d-flex h-100 flex-column">
    <enable-services
      :enabled-services="enabledServices"
      :loading="loading"
      :visible-services="visibleServices"
      :quick-message.sync="quickMessage"
      variant="user"
      @change="updateEnabledServices($event)"
    >
      <template #message>
        <quick-message
          :message="quickMessage ? quickMessage.message : ''"
          :show.sync="showQuickMessage"
          :type="quickMessage ? quickMessage.type : 'success'"
          with-icon
          class="mb-2"
        />
      </template>
    </enable-services>
  </div>
</template>

<script>
import EnableServices from "@zubut/common/src/components/EnableServices";
import ServiceType from "@zubut/common/src/constants/services/type";

export default {
  name: "CardDetailEnabledServices",

  components: {
    EnableServices
  },

  data() {
    return {
      loading: false,
      quickMessage: null,
      showQuickMessage: false,
      visibleServices: [ServiceType.NUM_RENT_PER_HOUR]
    };
  },

  computed: {
    enabledServices() {
      return this.$store.getters["user/getEnabledServices"];
    }
  },

  watch: {
    quickMessage(newMessage) {
      if (newMessage != null) {
        this.showQuickMessage = true;
      } else {
        this.showQuickMessage = false;
      }
    }
  },

  methods: {
    updateEnabledServices(enabledServices) {
      this.loading = true;
      this.$store
        .dispatch("user/updateEnabledServices", enabledServices)
        .then(() => {
          this.quickMessage = {
            message: "El tipo de entrega se actualizó con éxito",
            type: "success"
          };
        })
        .catch(err => {
          this.quickMessage = {
            message:
              "Ocurrió un error al intentar habilitar tus entregas. Vuelve a intentar más tarde o contacta a soporte.",
            type: "error"
          };
          this.$captureError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
