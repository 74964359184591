<template>
  <status-badge :status-name="statusName" :type="colorVariant" />
</template>

<script>
import StatusBadge from "./StatusBadge";
import DestinationStatus from "../constants/destinations/status";

export default {
  name: "StatusDestination",
  components: { StatusBadge },
  props: {
    status: {
      type: Number,
      required: true
    }
  },
  computed: {
    statusName() {
      return DestinationStatus.get[this.status];
    },
    colorVariant() {
      switch (this.status) {
        case DestinationStatus.NUM_CREATED:
          return "blue";
        case DestinationStatus.NUM_ACTIVE:
        case DestinationStatus.NUM_ONSITE:
        case DestinationStatus.NUM_PICKUP:
        case DestinationStatus.NUM_ONROUTE:
        case DestinationStatus.NUM_INWAREHOUSE:
        case DestinationStatus.NUM_COMPLETE:
        case DestinationStatus.NUM_DELIVER:
          return "green";
        case DestinationStatus.NUM_RETURNED:
        case DestinationStatus.NUM_COLLECTION_ATTEMPT:
          return "yellow";
        case DestinationStatus.NUM_CANCEL:
          return "red";
        default:
          return "red";
      }
    }
  }
};
</script>
