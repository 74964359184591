<template>
  <status-badge :status-name="statusName" :type="colorVariant" />
</template>

<script>
import StatusBadge from "@zubut/common/src/components/StatusBadge";
import ParcelStatus from "@zubut/common/src/constants/parcels/status";

export default {
  name: "StatusParcel",
  components: { StatusBadge },
  props: {
    status: {
      type: Number,
      default: -1
    }
  },
  computed: {
    statusName() {
      if (this.status >= 0) {
        return this.getStatusName(this.status);
      } else return "";
    },
    colorVariant() {
      return this.getPaymentStatusColorVariant(this.status);
    }
  },

  methods: {
    getStatusName(status) {
      return ParcelStatus.get[status];
    },

    getPaymentStatusColorVariant(paymentStatus) {
      switch (paymentStatus) {
        case ParcelStatus.NUM_COLLECTED:
        case ParcelStatus.NUM_IN_WAREHOUSE:
        case ParcelStatus.NUM_SCANNED:
        case ParcelStatus.NUM_SENT:
        case ParcelStatus.NUM_RETURNING_TO_ORIGIN:
        case ParcelStatus.NUM_RETURNING_TO_CLIENT_WAREHOUSE:
          return "blue";
        case ParcelStatus.NUM_DELIVERED:
        case ParcelStatus.NUM_RETURNED_TO_ORIGIN:
          return "green";
        case ParcelStatus.NUM_PENDING_COLLECTION:
        case ParcelStatus.NUM_DELIVERY_ATTEMPT:
          return "yellow";
        case ParcelStatus.NUM_PENDING_RETURN_TO_ORIGIN:
        case ParcelStatus.NUM_CANCELLED:
        case ParcelStatus.NUM_LOST:
          return "red";
        default:
          return "blue";
      }
    }
  }
};
</script>
