<template lang="html">
  <div class="addresses mt-4">
    <div
      v-if="favoriteAddress.name"
      class="favorite-address d-flex w-100 p-3 rounded justify-content-between"
    >
      <div class="d-flex">
        <z-icon name="StoreLocation" size="20px" class="mr-2" />
        <div class="text-sm">
          <div class="font-weight-bold">
            Dirección de {{ favoriteAddress.name }}
          </div>
          <div>{{ favoriteAddress.address }}</div>
          <div v-if="favoriteAddress.information">
            {{ favoriteAddress.information }}
          </div>
        </div>
      </div>
      <div role="button" @click="editAddress(favoriteAddress)">
        <z-icon name="Edit" hover />
      </div>
    </div>

    <div class="d-lg-flex justify-content-between mt-4">
      <z-search-input
        v-model="searchText"
        class="search-input"
        placeholder="Busca por nombre"
      />

      <z-button
        class="my-3 my-lg-0 float-right"
        variant="primary-alt"
        size="sm"
        @click="toggleModal(true)"
      >
        <div class="d-flex align-items-center">
          <z-icon class="mr-2" name="Add" variant="active" size="22" />
          Agregar dirección
        </div>
      </z-button>
    </div>
    <quick-message
      class="my-3 w-100"
      dismissible
      with-icon
      :show.sync="showMessage"
      :message="message"
      :type="typeOfMessage"
    />
    <z-table
      id="addresses"
      class="mt-3"
      responsive
      :items="addresses"
      :fields="fields"
      :filter="searchText"
      :sort-by="sortBy"
      :per-page="perPage"
      :current-page="currentPage"
      :busy="loading"
    >
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <loading-spinner />
        </div>
      </template>
      <template v-slot:empty>
        <quick-message
          class="my-3"
          show
          with-icon
          message="Aún no has registrado ningúna dirección favorita"
        />
      </template>
      <template v-slot:cell(actions)="row">
        <z-actions-menu>
          <template v-slot:button-content>
            <z-icon name="More" />
          </template>
          <b-dropdown-item @click="editAddress(row.item)">
            Editar
          </b-dropdown-item>
          <b-dropdown-item @click.stop="deleteAddress(row.item)">
            Eliminar
          </b-dropdown-item>
        </z-actions-menu>
      </template>
    </z-table>
    <z-table-pagination
      :total-rows="totalRows"
      :per-page.sync="perPage"
      :current-page.sync="currentPage"
    />
    <address-create
      :address="address"
      :show.sync="showModal"
      @address-success="handleSuccess"
      @address-error="handleError"
    />
  </div>
</template>

<script>
import AddressCreate from "@/app/views/Addresses/AddressCreate";
import ZTable from "@zubut/common/src/components/ZTable";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination";
import ZSearchInput from "@zubut/common/src/components/ZSearchInput";
import ZActionsMenu from "@zubut/common/src/components/ZActionsMenu";
import pagination from "@/mixins/pagination";

export default {
  name: "Addresses",

  components: {
    AddressCreate,
    ZTable,
    ZTablePagination,
    ZSearchInput,
    ZActionsMenu
  },

  mixins: [pagination],

  data() {
    return {
      fields: [
        { key: "name", label: "Nombre", sortable: true },
        { key: "phone", label: "Teléfono" },
        { key: "address", label: "Dirección" },
        { key: "actions", label: "" }
      ],
      sortBy: "name",
      address: {
        name: "",
        company: "",
        address: "",
        phone: "",
        information: "",
        defaultOrigin: false
      },
      showModal: false,
      loading: false,
      message: "",
      typeOfMessage: "success",
      showMessage: false,
      favoriteAddress: [],
      searchText: ""
    };
  },

  computed: {
    addresses() {
      return this.$store.getters["user/getAddresses"].filter(
        address => !address.defaultOrigin
      );
    },

    hasFavoriteAddress() {
      return this.$store.getters["user/getAddresses"].filter(
        address => address.defaultOrigin
      );
    },

    isFormValid() {
      const validFields = Object.keys(this.veeFields).some(
        key => this.veeFields[key].valid
      );
      const validAddress =
        this.address.address && this.address.address.length > 0;
      return validFields && validAddress;
    }
  },

  watch: {
    addresses: {
      immediate: true,
      handler(newVal) {
        this.totalRows = newVal.length;
        this.updateFavoriteAddress();
      }
    }
  },

  beforeMount() {
    if (this.addresses.length <= 0) {
      this.getAddresses();
    }
  },

  methods: {
    toggleModal(show) {
      this.showModal = show;
    },

    getAddresses() {
      this.loading = true;
      this.$store
        .dispatch("user/getFavoriteAddresses")
        .catch(err => {
          this.$captureError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    updateFavoriteAddress() {
      this.favoriteAddress = this.$store.getters["user/getAddresses"].filter(
        address => address.defaultOrigin
      );
      this.favoriteAddress.length > 0
        ? (this.favoriteAddress = this.favoriteAddress[0])
        : (this.favoriteAddress = {});
    },

    editAddress(address) {
      this.address = Object.assign({}, address);
      this.showModal = true;
    },

    deleteAddress(address) {
      this.$store
        .dispatch("user/deleteFavoriteAddress", address)
        .then(() => {
          this.message = "La dirección se ha borrado exitosamente";
          this.typeOfMessage = "success";
        })
        .catch(err => {
          this.$captureError(err);
          this.message = "Ocurrió un problema al borrar la dirección";
          this.typeOfMessage = "error";
        })
        .finally(() => (this.showMessage = true));
    },

    clearDefaultOrigin() {
      this.$store.dispatch("user/clearDefaultOrigin").then(() => {
        // TODO Notify user
      });
    },

    handleSuccess(message) {
      this.showMessage = true;
      this.message = message;
      this.typeOfMessage = "success";
    },

    handleError(message) {
      this.showMessage = true;
      this.message = message;
      this.typeOfMessage = "error";
    }
  }
};
</script>

<style lang="scss" scoped>
.addresses {
  .favorite-address {
    background-color: $ghost-white;
  }

  @include media-breakpoint-up(lg) {
    .search-input {
      width: 35%;
    }
  }
}
</style>
