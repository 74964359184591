<template>
  <b-dropdown
    class="z-dropdown z-dropdown-header dropdown-table-header"
    variant="light"
    boundary="window"
    no-caret
    :toggle-class="toggleClass"
  >
    <template #button-content>
      <div :class="buttonClass">
        {{ name }}
        <span class="z-dropdown-icon mr-2" :class="{ filtered: value != null }">
          <font-awesome-icon icon="filter" />
        </span>
      </div>
    </template>
    <div>
      <slot name="header">
        <b-dropdown-header>
          Mostrar
        </b-dropdown-header>
      </slot>
      <b-dropdown-item-button
        v-for="option in options"
        :key="`${$attrs.id}-${option.value}`"
        :active="value === option.value"
        @click="$emit('input', option.value)"
      >
        {{ option.text }}
      </b-dropdown-item-button>
    </div>
  </b-dropdown>
</template>

<script>
export default {
  name: "ZTableHeaderDropdown",

  props: {
    name: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: Number,
      default: null
    },
    toggleClass: {
      type: String,
      default: ""
    },
    buttonClass: {
      type: [String, Array, Object],
      default: ""
    }
  }
};
</script>

<style lang="scss">
.z-dropdown.dropdown-table-header {
  .dropdown-toggle {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $dim-gray;
    text-align: left;
    background-color: transparent;
    border-color: transparent;
    border-radius: 2px;
    font-weight: 500;
    font-size: 12px;
    min-height: 32px;

    &:hover,
    &:focus {
      box-shadow: none;
      background-color: transparent;
    }

    &:visited {
      color: $nero;
    }

    &::after {
      font-size: 1.4em;
      color: $nero;
    }

    &.z-dropdown-icon {
      margin-right: 5px;
    }
  }

  .dropdown-menu {
    padding-left: 0.5em;
    padding-right: 0.5em;
    border: none;
    box-shadow: 0 3px 9px 1px rgba($tangaroa, 0.2);

    .dropdown-item {
      padding-top: 0.5em;
      padding-bottom: 0.5em;

      &:hover,
      &.active,
      &:active,
      &:focus {
        color: $nero;
        background-color: rgba($royal-blue, 0.06);
      }

      &:focus {
        background-color: rgba($royal-blue, 0.096);
        outline: none;
      }
    }
  }

  .dropdown-header {
    padding-left: 1.4em;
    padding-right: 1.4em;
  }
}

.z-dropdown.dropdown-table-header {
  padding: 0px;

  .dropdown-toggle,
  .dropdown-toggle:hover {
    display: initial;
    background-color: transparent;
    border: none;
    color: $nero;
    font-weight: 600;
    font-size: 13px;
    padding: 0;
    min-height: initial;
  }

  &.show .dropdown-toggle {
    color: $nero;
    background-color: transparent;
  }
}
</style>
