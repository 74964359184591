<template>
  <div>
    <z-icon v-if="iconName" :name="iconName" />
  </div>
</template>

<script>
import VehicleType from "../constants/vehicles/type";
import ZIcon from "./ZIcon";

export default {
  name: "ZVehicle",

  components: {
    ZIcon
  },

  props: {
    type: {
      type: Number,
      validator: val =>
        [
          VehicleType.NUM_MOTORCYCLE,
          VehicleType.NUM_CAR,
          VehicleType.NUM_ECO,
          VehicleType.NUM_TRUCK
        ].indexOf(val) > -1
    }
  },

  data() {
    return {
      VehicleType
    };
  },

  computed: {
    iconName() {
      switch (this.type) {
        case VehicleType.NUM_MOTORCYCLE:
          return "Motorcycle";
        case VehicleType.NUM_CAR:
          return "Car";
        case VehicleType.NUM_ECO:
          return "Bike";
        case VehicleType.NUM_TRUCK:
          return "Truck";
        default:
          return null;
      }
    }
  }
};
</script>
