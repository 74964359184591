<template>
  <div class="card p-4 rounded">
    <p class="title">Wallet</p>
    <loading-spinner v-if="loading" />
    <div v-else>
      <quick-message
        class="mb-3"
        with-icon
        :show.sync="showMessage"
        :message="message"
        :type="messageType"
      />
      <div
        v-if="!hasActiveWallet"
        class="activate-wallet d-sm-flex justify-content-sm-between align-items-sm-center mb-4"
        :class="{ 'is-mu': !isZubut }"
        :style="`background-image: url('${walletBackgroundImage}');`"
      >
        <div class="font-weight-bold">
          <font-awesome-icon icon="zubut" class="z-logo mx-3" /> Wallet
        </div>
        <z-button
          class="mt-3 mt-sm-0"
          variant="secondary"
          text-color="primary"
          :loading="activating"
          @click="enableSPEI"
        >
          Activar
        </z-button>
      </div>
      <div
        v-if="hasWallet"
        class="balance-wallet"
        :class="{ 'is-mu': !isZubut }"
        :style="`background-image: url('${walletBackgroundImage}');`"
      >
        <div class="text-lavender-blue font-weight-semi-bold mb-1">Saldo</div>
        <div class="d-flex">
          <div class="font-weight-bold text-4xl">{{ walletAmount }}</div>
        </div>
        <div class="d-sm-flex justify-content-between mt-4">
          <div>
            <div
              class="text-lavender-blue text-uppercase font-weight-semi-bold mt-3 mb-1"
            >
              Clabe
            </div>
            <div>
              {{ clabeReference || "-" }}
            </div>
            <div class="text-lavender-blue font-weight-semi-bold mt-3 mb-1">
              Nombre
            </div>
            <div>Zubut Company</div>
          </div>
          <div class="mt-3 mt-md-0">
            <div class="text-lavender-blue font-weight-semi-bold mt-3 mb-1">
              Concepto / Referencia
            </div>
            <div>Recarga tu wallet</div>
            <div class="text-lavender-blue font-weight-semi-bold mt-3 mb-1">
              Banco
            </div>
            <div>STP (Sistema de transferencias y Pagos)</div>
          </div>
        </div>
      </div>
      <div class="py-4">
        <div class="font-weight-bold d-flex align-items-center">
          <z-icon class="icon" name="Ask" size="16" />

          <div>
            ¿Cómo recargo mi Wallet?
          </div>
        </div>

        <div v-if="!hasActiveWallet" class="mt-3 text-sm">
          <ol>
            <li>
              Activa tu Wallet presionando el botón
              <span class="font-weight-bold">Activar</span>
            </li>
            <li class="mt-3">
              Recarga tu wallet transfiriendo a tráves de tu aplicación
              bancaria.
            </li>
            <li class="mt-3">
              Ingresa en el campo de Banco: STP (Sistema de tansferencias y
              Pagos)
            </li>
            <li class="mt-3">
              Ingresa la CLABE que aparece en la tarjeta o accede al link de
              pago.
            </li>
          </ol>
          <div class="pl-5">Recuerda que tu CLABE es única.</div>
          <div class="pl-5">Mínimo de transferencia $500.00</div>
        </div>
        <div v-else class="mt-3 text-sm wallet-description">
          <div>
            Recarga tu wallet transfiriendo a tráves de tu aplicación bancaria a
            la CLABE de tu cuenta zubut descrita en la tarjeta, o accede al link
            de pago.
          </div>
          <div class="mt-3">Mínimo de transferencia $500.00</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatMoney } from "@/utils/money";

export default {
  name: "ProfilePaymentWallet",

  data() {
    return {
      activating: false,
      showMessage: false,
      messageType: "error",
      message: "",
      walletBackgroundImage: require("@/assets/img/wallet-background.png"),
      loading: false
    };
  },

  computed: {
    hasActiveWallet() {
      return this.clabeReference ? true : false;
    },
    hasWallet() {
      return this.$store.getters["user/hasWallet"];
    },
    clabeReference() {
      return this.$store.getters["user/getClabeReference"];
    },
    walletAmount() {
      return formatMoney(this.$store.getters["user/getWalletAmount"] / 100);
    },
    isZubut() {
      return this.$store.getters["isZubut"];
    }
  },

  created() {
    this.getWallet();
  },

  methods: {
    getWallet() {
      if (!this.hasWallet) {
        this.loading = true;
        this.$store
          .dispatch("user/getWallet")
          .catch(err => {
            this.$captureError(err);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    enableSPEI() {
      this.activating = true;
      this.$store
        .dispatch("user/enableSPEI")
        .then(res => {
          this.showMessage = true;
          this.messageType = "success";
          this.message = "Wallet activada con éxito";
        })
        .catch(err => {
          this.showMessage = true;
          this.messageType = "error";
          this.message =
            err?.message || "Ocurrió un error al intentar activar tu Wallet";
        })
        .finally(() => {
          this.activating = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.z-logo {
  font-size: 1.5em;
}

.title {
  font-size: 14px;
  font-weight: 600;
}

.activate-wallet,
.balance-wallet {
  color: $white;
  border-radius: 12px;
  background-color: #0059bf;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.activate-wallet.is-mu,
.balance-wallet.is-mu {
  filter: hue-rotate(323deg);
}

.activate-wallet {
  padding: 24px;

  button {
    width: 100%;
  }
}

.balance-wallet {
  padding: 2rem 2rem;
}

.icon {
  margin-right: 10px;
}

.wallet-description {
  margin-left: 26px;
}

@include media-breakpoint-up(sm) {
  .activate-wallet button {
    width: auto;
  }
}
</style>
