<template>
  <b-card class="profile-wrapper p-sm-2">
    <div>
      <div class="pt-0">
        <b-row>
          <b-col
            class="d-flex flex-column flex-md-row justify-content-md-between"
          >
            <div>
              <span class="card-title">
                {{ user.name }}
              </span>
              <p class="font-weight-semi-bold text-grey">
                {{ clientTypeText }}
              </p>
            </div>
            <div v-if="isZubut">
              <a
                role="button"
                href="https://zubut.com/terminos-y-condiciones"
                target="_blank"
                class="label-info d-flex align-items-center"
              >
                <z-icon name="Ask" size="14" variant="active" class="mr-2" />
                <span class="text-main font-weight-semi-bold text-sm">
                  Ayuda
                </span>
              </a>
            </div>
            <div v-else>
              <a
                role="button"
                href="https://mensajerosurbanos.com/politicas-de-privacidad-mexico/?lang=mx"
                target="_blank"
                class="label-info d-flex align-items-center"
              >
                <z-icon name="Ask" size="14" variant="active" class="mr-2" />
                <span class="text-main font-weight-semi-bold text-sm">
                  Ayuda
                </span>
              </a>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="isLoading && !user">
        <loading-spinner />
      </div>
      <div class="py-4">
        <z-tabs content-class="p-0" pills responsive>
          <z-tab
            title="Perfil"
            :active="!$route.query.tab || $route.query.tab === 'perfil'"
            @click="changeTab({ name: 'profile' })"
          >
            <profile-account :user="user" />
          </z-tab>
          <z-tab
            title="Direcciones favoritas"
            :active="$route.name === 'addresses'"
            lazy
            @click="changeTab({ name: 'addresses' })"
          >
            <addresses />
          </z-tab>
          <z-tab
            title="Historial"
            lazy
            :active="$route.name === 'deliveries'"
            @click="changeTab({ name: 'deliveries' })"
          >
            <deliveries />
          </z-tab>
          <z-tab
            v-if="isCompany"
            lazy
            title="Sucursales"
            :active="$route.name === 'branches'"
            @click="changeTab({ name: 'branches' })"
          >
            <profile-branches :client-id="user.id" />
          </z-tab>
          <z-tab
            lazy
            title="Métodos de pago"
            :active="$route.name === 'payment-methods'"
            @click="changeTab({ name: 'payment-methods' })"
          >
            <div class="mt-4">
              <profile-payment :client-id="user.id" :wallet="wallet" />
            </div>
          </z-tab>
          <z-tab
            v-if="isZubut"
            lazy
            title="Facturación"
            :active="$route.name === 'billing'"
            @click="changeTab({ name: 'billing' })"
          >
            <profile-billing-data
              :key="billingDataKey"
              :client-id="user.id"
              :user-email="user.email"
              :additional="user.additional"
              :billing-data="user.billingData"
              @updated="handleBillingDataUpdate"
            />
          </z-tab>
        </z-tabs>
      </div>
    </div>
  </b-card>
</template>

<script>
import Type from "@zubut/common/src/constants/clients/type";
import ProfileBranches from "./ProfileBranches";
import ProfileAccount from "./ProfileAccount/ProfileAccount";
import Addresses from "../Addresses/Addresses";
import ProfileBillingData from "./CardBillingData";
import ProfilePayment from "./ProfilePayment/ProfilePayment";
import Deliveries from "../Deliveries/Deliveries";

export default {
  name: "Profile",

  components: {
    ProfileAccount,
    Addresses,
    ProfileBillingData,
    ProfilePayment,
    ProfileBranches,
    Deliveries
  },

  data() {
    return {
      isLoading: false,
      billingDataKey: 0
    };
  },

  computed: {
    isZubut() {
      return this.$store.getters["isZubut"];
    },

    user() {
      return this.$store.state.user;
    },
    clientTypeText() {
      return this.$store.getters["user/clientTypeText"];
    },
    wallet() {
      return this.user?.wallet ?? null;
    },
    isCompany() {
      const type = this.user?.type || 0;
      return type === Type.NUM_COMPANY;
    }
  },

  methods: {
    changeTab(route) {
      this.$router.push(route).catch(error => {
        this.$captureError(error);
      });
    },

    handleBillingDataUpdate() {
      this.billingDataKey++;
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-wrapper {
  background-color: $white;
  border-radius: 1em;
  border: 1px solid $solitude;
}
</style>
